import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

const Button = ({ href, title, target, rel, children }) => (
  <Link className="button" to={href} title={title} target={target} rel={rel}>
    {children}
  </Link>
)

Button.propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string,
  target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top']),
  rel: PropTypes.oneOf([
    'alternate',
    'author',
    'bookmark',
    'external',
    'help',
    'license',
    'next',
    'nofollow',
    'noreferrer',
    'noopener',
    'noopener noreferrer',
    'noreferrer noopener',
    'prev',
    'search',
    'tag',
  ]),
  children: PropTypes.node.isRequired,
}

export default Button
