import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import Button from '../components/button'
import Layout from '../components/layout'
import SEO from '../components/seo'

const IndexPage = ({ data }) => (
  <Layout>
    <SEO
      title="Colin Genlloud - Suffolk based artist and painter"
      keywords={[`colin`, `genlloud`, `artist`, `painter`, `suffolk`, `norfolk`, 'crete']}
    />
    <ul className="flex flex-wrap -m-4 list-reset">
      {data.allMarkdownRemark.edges.map(item => {
        const { slug } = item.node.fields
        const { title } = item.node.frontmatter
        const { img } = item.node.frontmatter

        return (
          <li className="p-4 w-full md:w-1/2" key={slug}>
            <Link to={slug}>
              <Img sizes={img.childImageSharp.sizes} alt={title} />
            </Link>
          </li>
        )
      })}
    </ul>
    <div className="flex justify-center mt-8">
      <Button href="/art">More art</Button>
    </div>
  </Layout>
);

export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___order], order: DESC }, limit: 12) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            img {
              childImageSharp {
                sizes(
                  maxWidth: 660
                  maxHeight: 660
                  background: "rgba(255,255,255,1)"
                  fit: CONTAIN
                ) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    }
  }
`
